// import stylesheets
import '../sass/main.sass'

// import npm packages
import Swup from 'swup'
import $ from 'jquery'
import 'jquery-ui'

const swup = new Swup()

const nav = {
	commissioned : document.getElementById("commissioned"),
	personal : document.getElementById("personal"),
	about : document.getElementById("about")
}

const modal = document.getElementById("modal")
const modaled_image = document.getElementById("modaled-image")

const petals = [...document.getElementsByClassName("petal-wrapper")]

let commissioned

// declare functions
const netlifyIdentity = () => {
	if (window.netlifyIdentity) {
		window.netlifyIdentity.on("init", user => {
		  if (!user) {
		    window.netlifyIdentity.on("login", () => {
		      document.location.href = "/admin/"
		    })
		  }
		})
	}
}

const checkLoc = () => {
	let loc = window.location
	loc = loc.pathname.split("/")
	loc[1] ? updateNav(loc[1]) : updateNav("home")

	if (loc[1] == "commissioned") {
		loc[2] ? updateCommissioned(loc[2]) : false
		// highlightCommissioned()
	} else if (loc[1] == "personal") {
		randomizePersonal()
	}
}

const updateNav = (id) => {
	Object.keys(nav).forEach(key => {
		nav[key].classList.remove("active")
		nav[key].href = "/" + key
	})
	if (id != "home") {
		nav[id].classList.add("active")
		nav[id].href = "/"
	}
}

const updateCommissioned = (id) => {
	commissioned = [...document.getElementsByClassName("commissioned-project")]
	// commissioned.forEach(i => i.classList.remove("active"))
	
	let elem = document.getElementById(id)
	elem ? elem.classList.add("active") : false

	shuffleCommissioned()
}

const initModals = () => {
	[...document.getElementsByClassName("modal-image")].forEach(img => {
		img.addEventListener("click", () => {
			showModal(img)
		})
	})
}

const showModal = (img) => {
	modaled_image.src = img.src
	modal.classList.toggle('active')
	// modal.style.backgroundImage = 'url(' + img.src + ')'
	document.body.classList.toggle("modal-open")

	modal.addEventListener("click", hideModal)
}

const hideModal = () => {
	modal.classList.toggle('active')
	document.body.classList.toggle("modal-open")
}

const shuffleCommissioned = () => {
	let items = [...document.getElementsByClassName("commissioned-item")]
	if (items.length) {
		items[0].parentElement.classList.contains("shuffle-true") ? shuffleOrder(items) : false
	}
	items.forEach(item => {
		let random = Math.floor(Math.random() * 100) + 1
		let random_2 = Math.floor(Math.random() * 80) + 1 - 30
		item.style.left = random + "%"
		item.style.transform = "translateX(-" + random + "%)"
		item.style.marginBottom = random_2 + "px"
	})
}

// const highlightCommissioned = () => {
// 	let commissioned = [...document.getElementsByClassName("commissioned-project")]
// 	commissioned.forEach(i => i.addEventListener("click", function(){
// 		commissioned.forEach(i => i.classList.remove("active"))
// 		i.classList.add("active")
// 	}))
// }

const randomizePersonal = () => {
	let items = [...document.getElementsByClassName("personal-image")]
	items.forEach(item => {
		if (item.parentElement.classList.contains("randomize-true")) {
			let random_1 = Math.floor(Math.random() * 70) + 1
			let random_2 = Math.floor(Math.random() * 70) + 1
			let random_3 = Math.floor(Math.random() * 50) + 10
			item.style.paddingTop = random_1 + "px"
			item.style.paddingBottom = random_2 + "px"
			item.style.marginRight = random_3 + "px"
		}
	})
}

const shuffleOrder = (items) => {
  for (let i = 0; i < items.length; i++) {
    var target = Math.floor(Math.random() * items.length)
    var target2 = Math.floor(Math.random() * items.length)
    items[target].parentNode.insertBefore(items[target2], items[target])
  }
}

petals.forEach(petal => {
	petal.style.top = Math.random() * ($(window).innerHeight() - 100) + "px"
	petal.style.left = Math.random() * ($(window).innerWidth() - 100) + "px"

	// petal.addEventListener('click', () => {
	// 	petal.style.transform = 'rotate(' + (Math.floor(Math.random() * 360) + 1 - 180) + 'deg)'
	// })
})

$(document).ready(() => {
  const petals = $('.petal-wrapper');

  petals.each((index, petal) => {
    const $petal = $(petal);
    const startPosition = $petal.position();
    let isDragging = false;
    let isAnimating = true; // Track if the animation should be resumed

    $petal.draggable({
      start: () => {
        // Pause the animation when dragging starts
        $petal.stop(true);
        isDragging = true;
        isAnimating = false; // Pause the floating animation while dragging
      },
      stop: () => {
        // Resume the animation when dragging stops
        isDragging = false;
        isAnimating = true; // Resume the floating animation
        $petal.animateDiv();
      },
    });

    $petal.animateDiv = () => {
      if (isAnimating) {
        const newPosition = makeNewPosition();
        const speed = calcSpeed(
          [startPosition.top, startPosition.left],
          newPosition
        );

        $petal.animate(
          {
            top: newPosition[0],
            left: newPosition[1],
          },
          speed,
          () => {
            $petal.animateDiv();
          }
        );
      }
    };

    // $petal.click(() => {
		//   if (!isDragging) {
		//     const rotation = Math.floor(Math.random() * 4) * 90; // Randomly select 0, 1, 2, or 3 and multiply by 90
		//     $petal.css('transform', 'rotate(' + rotation + 'deg)');
		//   }
		// });

    // Start the animation initially
    $petal.animateDiv();
  });
});

const makeNewPosition = () => {
  const h = $(window).innerHeight() - 100;
  const w = $(window).innerWidth() - 100;
  const nh = Math.floor(Math.random() * h);
  const nw = Math.floor(Math.random() * w);
  return [nh, nw];
};

const calcSpeed = (prev, next) => {
  const x = Math.abs(prev[1] - next[1]);
  const y = Math.abs(prev[0] - next[0]);
  const greatest = x > y ? x : y;
  const speedModifier = Math.random() / 50;
  const speed = Math.ceil(greatest / speedModifier);
  return speed;
};



// swup event listeners
swup.on('contentReplaced', () => {
 checkLoc()
 initModals()
})

// call functions on load
netlifyIdentity()
checkLoc()
initModals()